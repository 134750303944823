.center-item{
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: red;
}

ion-row.custom-row {
    background-color: #eee;
    border: solid 1px #fff;
    color: #000;
    text-align: center;
}

input[type = "file"]{
    width:70%;
}

ion-card img{
    width: 100% !important;
}

.custom-textarea{
    border :0; width:100%;
}

a{
    text-decoration: none;
    color: #574a44 !important;
}

a:hover{
    color: #3171e0 !important;
}

.custom-button{
    background-color: #0ec254 !important;
}

.custom-select{
    width: 50%;
    padding:5px;
    border: 1px solid #e3dace !important;
}

.custom-select:hover{
    background-color:#ea6f52 !important;
}

.custom-select option:hover{
    background-color:#ea6f52 !important;
}

.custom-bg{
    background-color: #c96c10 !important;

}


form{
    width: 100% !important;
}

.custom-ion-card-style{
    height: 280px !important;
    width: 55vw;
}

.custom-ion-card-img{
    height: 150px !important;
}
.custom-ion-content-style{
    height: 100px;
}

.custom-category-header-title{
    background-color: #fff;
    padding: 0 15px 0 15px;
    color: #ea6f52;
}

p{
    margin-bottom: 10px !important;
    text-align: justify;
}

ion-row ion-col.custom-column{
    border: 1px solid #574a44 !important;
}

.custom-action-red{
    background-color: #ea6f52;
    border-radius: 10px;
    color: #fff !important;
    padding:5px;
}

.custom-action-dark-green{
    background-color: #574a44;
    border-radius: 10px;
    color: #fff !important;
    padding:5px;
}


.custom-slide-parent {
    display: flex;
    width: 100%;
    overflow-x: scroll; /* Use overflow-x to specify horizontal scrolling */
    scrollbar-width: none;
    -ms-overflow-style: none; /* Internet Explorer and Microsoft Edge */
}

.custom-slide-parent::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.custom-menu-title{
    display: flex !important;
    justify-content: center !important ;
    align-items: center !important;

}

.custom-search-bar-input,.searchbar-input {
    border-radius: 1.5em !important;
    width:100% !important;
    box-shadow: none !important;
    border: 1px solid #d7d8da !important;
    background-color: #ffffff !important;
}


.custom-padding{
    padding: 0 15px 0 15px;
}

.custom-item-align{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-filter-button{
    border: none;
    display: block;
    position: relative;
}

.custom-filter img{
    width: 20px !important;
}

.custom-pointer {
    cursor: pointer;
}

.custom-bold-text{
    font-weight: bold;
}

.custom-margin-bottom{
    margin-bottom: 4px;
}

.custom-margin-10p{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.custom-margin-top{
    margin-bottom: 4px;
}

.custom-slide-parent ion-card-header{
    margin: 0 !important;
    padding-top:3px; padding-bottom:3px;
}
ion-card-title.custom-ion-card-title{
    font-size: 0.9em;
    margin: 0 !important;
}

.custom-ion-content-style{
    color:#262626;
}


.custom-select2 {
    position: relative;
    margin-bottom: 20px;
}

.custom-select2 select {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 10px;
    appearance: none;
    background-color: white;
    cursor: pointer;
    width: 100%;
}

.custom-select2::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 14px;
    pointer-events: none;
    color:#000;
}

/* Optional: Hover effect */
.custom-select2:hover select {
    border-color: #999;
}

/* Optional: Focus effect */
.custom-select2 select:focus {
    outline: none;
    border-color: #555;
    box-shadow: 0 0 5px rgba(85, 85, 85, 0.5);
}


/* Apply styles to the input */
.custom-date input{
    border: 1px solid #ccc;
    border-radius: 10px;
    color: #000;
    padding: 4px;
    font-size: 0.8em;
    width: 100% !important;
}

.custom-date span{
    font-size: 0.9em;
    color:#fff;
}

.custom-date label{
    margin-top: 10px !important;
}

.custom-date-input {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
}

.custom-date-input input[type="date"] {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    appearance: none; /* This is to disable default browser styling */
    background-color: white;
    cursor: pointer;
    width: 100% !important;
    border-radius: 10px;
    color: #000;
}

/* Style the pseudo-element that appears for the calendar icon */
.custom-date-input::after {
    content: '\1F4C5'; /* Unicode for calendar icon */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 14px;
    pointer-events: none;
}

/* Optional: Hover effect */
.custom-date-input:hover input[type="date"] {
    border-color: #999;
}

/* Optional: Focus effect */
.custom-date-input input[type="date"]:focus {
    outline: none;
    border-color: #555;
    box-shadow: 0 0 5px rgba(85, 85, 85, 0.5);
}

.custom-ion-toolbar{
    padding: 15px;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    font-family: -apple-system, ".SFNSText-Regular", "Helvetica Neue", "Roboto", "Segoe UI", sans-serif;
}

.toggle {
    cursor: pointer;
    display: inline-block;
}

.toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 58px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
}
.toggle-switch:before, .toggle-switch:after {
    content: "";
}
.toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
    background: #c96c10;
}
.toggle-checkbox:checked + .toggle-switch:before {
    left: 30px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
}

ion-col ion-icon{
    color:#c88b66 !important
}

.profile ion-label:first-child{
    color: #574a44;
}

.record ion-row{
    font-size: 0.8em;
    padding: 5px 0 5px 0;
    color: #002e31;
}
.record-main ion-row{
    font-size: 0.88em;
    padding: 5px 0 5px 0;
    color: #002e31;
}
.record-main h4, .record h4{
    color: #fff;
}


.record-single ion-row{
    font-size: 0.85em;
    padding: 5px 0 5px 0;
    color: #002e31;
}

.record ion-row ion-col, .record-main ion-row ion-col{
    display: flex;
    justify-content: center;
    align-items: center;
}


.record ion-row ion-col a{
    color: #fff !important;
}

.general-style a ion-button{
    font-size: 0.7em!important;
}

.custom-gray-bg{
    background-color: #eeeeee;
}
.custom-white-bg{
    background-color: #ffffff;
}

@media (min-width: 768px) {
    .custom-container{
        width: 45%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 30px;
    }
    .custom-ion-card-style{
        height: 340px !important;
        width: 20vw;
    }
}

.custom-search-bar-layout{
    position: relative;
    margin-bottom: 60px !important;
}

.custom-search-bar{
    position: absolute;
    width: 100% !important;
}

.custom-searchbar-input{
    width: 100% !important;
}

.custom-filter{
    position: absolute;
    right: 25px;
    top:15px;
    background-color: #fff;
    padding:4px;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}


form div ion-input{
    border-bottom:1px solid #92949c;
}


.sc-ion-input-md{
    color:#ffffff;
    padding-left: 2px !important;
}

ion-card{
    background-color: #fff !important;
}
.custom-item-align{
    color: #fff !important;
}

.custom-button-primary-bg-color{
    background: rgb(201,108,16);
    background: linear-gradient(0deg, rgba(201,108,16,1) 0%, rgba(247,169,90,1) 44%);
    color: #fff !important;
}

.custom-single-event-button{
    background: rgb(201,108,16);
    background: linear-gradient(0deg, rgba(201,108,16,1) 0%, rgba(247,169,90,1) 44%);
    color: #fff !important;
    border-radius: 15px;
}

form button, .custom-get-ticket-button{
    background: rgb(201,108,16);
    background: linear-gradient(0deg, rgba(201,108,16,1) 0%, rgba(247,169,90,1) 44%);
}

ion-card-title{
    color: #000 !important;
}

.text-orange{
    color: #c96c10 !important;
}

.text-white{
    color: #fff !important;
}

.bg-orange{
    background-color: #c96c10 !important;
}
.text-tint{
    color: #1e2023 !important;
}

.dark-shade-bg{
    background-color: #d7d8da;
}

.dark-tint-bg{
    background-color: #f5f6f9;
}

.round-circle{
    border-radius: 50%;
}

.inc-dec-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

form h2 ,form h4{
    color: #ffffff;
}

form{
    padding: 5px!important;
}

form button{
    border-radius: 20px !important;
    padding: 15px;
    margin-top: 15px !important;
}

form textarea{
    width: 100% !important;
}

form select{
    width: 100% !important;
    padding: 5px;
}

form select , form textarea , .custom-special-input, ion-input{
    margin-top: 20px;
    border-radius: 10px;
}


/*form div {*/
/*    margin-top: 1px;*/
/*}*/

form input[type="date"], form input[type="time"],form select{
    color: #000 !important;
    border-radius: 10px;
    border: none;
}

form input[type="file"]{
    border-radius: 10px;
    border: none;
}

.custom-text-right{
    text-align: right !important;
}

ion-input{
    border: 1px solid #eee !important ;
}

.custom-featured-event-caption{
    font-size: 9px;
    text-align: center;
}

.custom-full-width{
    width: 49% !important;
}

form textarea, form textarea:focus{
    border: 0;
    color: #0d0d0d;
}

.custom-active-bg{
    background-color: #c96c10 !important;
    color: #ffffff;
}

.custom-inactive-bg{
    background-color: #f4f5f8 !important;
    color: #000;
}

ion-label, ion-item{
    color: #ffffff !important;
}

ion-label:hover, ion-item:hover{
    color: #ffffff !important;
}

.custom-image-circle{
    border-radius: 50% !important;
    margin-right: 5px;
    margin-left: 5px;
    width: 75px !important;
    height: 75px !important;

}

.general-style h5{
    color:#fff;
}


.custom-content-text-on-home h6{
    font-size: 1em !important;
    font-weight: bold;
}

.custom-content-text-on-home span{
    font-size: 0.9em !important;
}


.custom-featured-content-parent {
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap; /* Prevents child elements from wrapping to the next line */
}

.custom-featured-content-child {
    display: inline-block; /* Use inline-block to make the elements inline and horizontally scrollable */
    width: 80px;
    height: 90px;
    margin-left: 5px;
    position: relative;
}


.custom-featured-content-child img{
    width: 80px;
    height: 80px;
}

.custom-qrcode-image img{
    width: 50px !important;
}


.custom-single-user{
    background-color: #1e2023;
    color: #fff;
    font-size: 1em;
}

a button{
    width:auto !important;
    display: inline;
    background-color: #f5f6f9;
    color: #1e2023;
    margin-left: 10px;
    margin-top: 15px;
    border-radius: 15px;
    font-size: 0.8em;
}

a button:hover{
    background-color: #c96c10;
    color:#fff;
}

a button.custom-edit-button, a button.custom-logout-button{
    width: auto !important;
    border-radius: 0 30px 30px 0;
    background: rgb(208,208,208);
    background: linear-gradient(0deg, rgba(208,208,208,1) 0%, rgba(231,231,231,1) 44%);
    color: #1e2023;
    display: flex;
    align-items: center;
    padding: 5px;
    border: 3px solid #fff;
}

a button.custom-toggle-content-button{
    width: auto !important;
    border-radius: 0 15px 15px 0;
    background-color: #e7e7e7 !important;
    color: #1e2023;
    display: flex;
    align-items: center;
    padding: 2px;
}


form button.custom-button-more-action{
    width: 140px !important;
    background: rgb(208,208,208);
    background: linear-gradient(0deg, rgba(208,208,208,1) 0%, rgba(231,231,231,1) 44%);
    color: #1e2023;
    border-radius: 15px 15px 15px 15px !important;
    border: 1px dashed #1e2023;
    padding: 5px !important;
}

#profile-picture {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

#upload-button {
    display: none;
}

.custom-company-profile ion-label{
    color : #1e2023 !important;
    line-height: 2em !important;
}

.alert-title{
    color: #c96c10 !important;
}

.native-input{
}

.custom-update-notification{
    background-color: #eeeeee;
    padding: 15px;
    border-radius: 15px;
}

.custom-update-notification button{
    background-color: #c96c10 !important;
}

.custom-verification{
    background-color: #fff;
    height: 200px;
    padding: 30px !important;
    width: 90% !important;
}
.custom-verification div{
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    margin-top: 10px;
}

.custom-verification div input{
    border: 1px solid #1e2023 ;
    height: 50px;
    float: left;
    width: 20%;
    text-align: center;
    color: #1e2023;
    font-size: 40px;
    outline: none;
}

.custom-verification div input:focus{
    border: 2px solid #1e2023; ;
    outline: none;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
   -webkit-appearance: none;
    margin:0;
}

